<template>
  <div class="card card-custom gutter-b h-100">
    <!--    header-->
    <div class="card-header">
      <div class="card-title">
        Report ca test
      </div>
      <div class="card-title">
        <button class="font-weight-bold font-size-3 btn btn-primary" @click="exportExcel()" :disabled="loading"><i
            v-if="loading" class="el-icon-loading"></i> Xuất excel
        </button>
      </div>
    </div>
    <!--    body-->
    <div class="card-body">
      <div class="row">
        <div class="col-md-3 col-sm-12 mb-3">
          <h4 style="font-size: 13px">Thời gian</h4>
          <el-date-picker
              v-model="reportTime"
              type="daterange"
              range-separator="-"
              start-placeholder="Ngày bắt đầu"
              end-placeholder="Ngày kết thúc"
              style="width: 100%"
              :clearable="false"
          >
          </el-date-picker>
        </div>
        <div class="col-md-2  col-sm-12 mb-3">
          <h4 style="font-size: 13px">Văn phòng</h4>
          <el-select v-model="office" placeholder="Chọn Văn Phòng" style="width: 100%" :clearable="false" filterable
                     :multiple="true" @change="selectOffice">
            <el-option
                v-for="item in listBranch"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-2 col-sm-12 mb-3">
          <h4 style="font-size: 13px">Hình thức trực test</h4>
          <el-select v-model="typeTest" placeholder="Chọn hình thức" style="width: 100%" :clearable="false">
            <el-option
                v-for="item in testForm"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-2 col-sm-12 mb-3">
          <button type="button" class="btn btn-primary" style="margin-top: 22px" @click="getListReport()"
                  :disabled="loadingSearch"><i v-if="loadingSearch" class="el-icon-loading"></i>Tìm kiếm
          </button>
        </div>
      </div>
      <!--      table-->
      <div class="mt-10 table-responsive">
        <table class="table table-bordered text-center table-vertical-center ">
          <thead>
          <tr style="background-color: #F8F8F8 ; font-size: 13px">
            <th></th>
            <th>Tổng</th>
            <th>Ca sáng</th>
            <th>Ca chiều</th>
            <th>Ca tối</th>
            <th>Ca tối 2</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(name,index) in nameReport" :key="index">
            <td class="text-left" style="width: 370px">
              {{ name.name }}
            </td>
            <td>{{ reports[name.key]?.total }}{{name.key === 'full_slot' ?'%':'' }}</td>
            <td>{{ reports[name.key]?.morning }}{{name.key === 'full_slot' ?'%':'' }}</td>
            <td>{{ reports[name.key]?.afternoon }}{{name.key === 'full_slot' ?'%':'' }}</td>
            <td>{{ reports[name.key]?.evening }}{{name.key === 'full_slot' ?'%':'' }}</td>
            <td>{{ reports[name.key]?.evening_overtime }}{{name.key === 'full_slot' ?'%':'' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import {
  EXPORT_REPORT_EXCEL,
  GET_LIST_BRANCH_IS_EDUTALK,
  GET_LIST_REPORT
} from "@/core/services/store/test/testInput.module";
import moment from "moment-timezone";
import {exportExcel} from "@/core/filters";

export default {
  name: "ReportTest",
  async mounted() {
    this.reportTime = [new Date(), new Date()];
    await this.getListBranchIsEdutalk()
    await this.getListReport();
  },
  data() {
    return {
      reportTime: null,
      office: [0],
      typeTest: 0,
      reports: {},
      nameReport: [
        {
          name: 'Tổng số ca đặt lịch (1)',
          key: 'total_booking'
        },
        {
          name: 'Số ca hủy (2) = (5) + (6) + (7)',
          key: 'total_cancel'
        },
        {
          name: 'Tỷ lệ hủy (3) = (2) / (1) *100%',
          key: 'percent_cancel'
        },
        {
          name: 'Số ca hoàn thành (4) ',
          key: 'total_success'
        },
        {
          name: 'Số ca hủy do TVV hủy lịch (5)',
          key: 'total_cancel_due_tvv'
        },
        {
          name: 'Số ca hủy do Điều phối hủy lịch (6)',
          key: 'total_cancel_due_coordinator'
        }, {
          name: 'Số ca hủy do Khách hàng không test (7)',
          key: 'total_customer_cannot_tested'
        },
        {
          name: 'Số ca hủy do Sale/TVV không xác nhận (8)',
          key: 'total_sale_tvv_not_confirm'
        },
        {
          name: 'Số ca chưa test general (đã test speaking) (9)',
          key: 'total_incomplete_general'
        },
        {
          name: 'Số ca trực (10)',
          key: 'total_slots'
        },
        {
          name: 'Tỉ lệ đặt lịch (11) = [(1) - (5) - (6) - (8)] / (10)',
          key: 'percent_total_slot'
        },
        {
          name: 'Tỉ lệ full slot: (12) = (1) / (10)',
          key: 'full_slot'
        },
      ],
      listBranch: [],
      testForm: [{name: 'Tất cả', id: 0}, {name: 'Online', id: 1}, {name: 'Offline', id: 2},],
      loading: false,
      loadingSearch: false
    }
  },
  methods: {
    getListReport() {
      this.loadingSearch = true;
      let payload = {
        start_date: this.formatDate(this.reportTime[0]),
        end_date: this.formatDate(this.reportTime[1]),
        branch_ids: this.office[0] === 0 ? [] : this.office,
        type: this.typeTest
      }
      return this.$store.dispatch(GET_LIST_REPORT, payload).then((res) => {
        this.reports = res.data;
      }).catch((e) => {
      }).finally(() => {
        this.loadingSearch = false;
      })
    },
    formatDate(data1) {
      return moment(data1).format('yyyy-MM-DD')
    },
    getListBranchIsEdutalk() {
      return this.$store.dispatch(GET_LIST_BRANCH_IS_EDUTALK).then((res) => {
        this.listBranch = [{name: 'Tất cả', id: 0}, ...res.data];
      })
    },
    selectOffice(selectedOfficeList = []) {
      // office = 0 nghĩa là chọn tất cả
      const lastIndex = selectedOfficeList?.length - 1
      if ((lastIndex < 0 || selectedOfficeList[lastIndex] === 0)) {
        this.office = [0]
        return
      }
      this.office = selectedOfficeList.filter((office) => office)
    },
    exportExcel() {
      this.loading = true;
      const HEADER_ROW_EXPORT_DSKH = [
        {
          key: 'Mã tác vụ',
          value: 'Mã tác vụ',
        },
        {
          key: 'Số điện thoại khách hàng',
          value: 'Số điện thoại khách hàng',
        },
        {
          key: 'Loại tác vụ',
          value: 'Loại tác vụ',
        },
        {
          key: 'Trạng thái',
          value: 'Trạng thái',
        },
        {
          key: 'Hình thức test',
          value: 'Hình thức test',
        },
        {
          key: 'Trung tâm',
          value: 'Trung tâm',
        },
        {
          key: 'Chi nhánh test',
          value: 'Chi nhánh test',
        },
        {
          key: 'Thời gian tạo tác vụ',
          value: 'Thời gian tạo tác vụ',
        },
        {
          key: 'Thời gian bắt đầu',
          value: 'Thời gian bắt đầu',
        },
        {
          key: 'Thời gian kết thúc',
          value: 'Thời gian kết thúc',
        },
        {
          key: 'Thời gian bắt đầu thực tế',
          value: 'Thời gian bắt đầu thực tế',
        },
        {
          key: 'Thời gian hủy',
          value: 'Thời gian hủy',
        },
        {
          key: 'Mã người đặt lịch',
          value: 'Mã người đặt lịch',
        },
        {
          key: 'Loại tài khoản đặt lịch',
          value: 'Loại tài khoản đặt lịch',
        },
        {
          key: 'Mã người phụ trách',
          value: 'Mã người phụ trách',
        },
        {
          key: 'Lý do hủy',
          value: 'Lý do hủy',
        },
        {
          key: 'speaking',
          value: 'Speaking',
        },
        {
          key: 'writing',
          value: 'Writing',
        },
        {
          key: 'reading',
          value: 'Reading',
        },
        {
          key: 'listening',
          value: 'Listening',
        },
        {
          key: 'vocabulary',
          value: 'Vocabulary',
        },
      ]
      let payload = {
        start_date: this.formatDate(this.reportTime[0]),
        end_date: this.formatDate(this.reportTime[1]),
        branch_ids: this.office[0] === 0 ? [] : this.office,
        type: this.typeTest
      }
      this.$store.dispatch(EXPORT_REPORT_EXCEL, payload).then((res) => {
        exportExcel(HEADER_ROW_EXPORT_DSKH, res.data, 'Danh sách export test đầu vào.xlsx', false)

      }).finally(() => {
        this.loading = false;
      })
    }
  },
}
</script>
<style scoped>

</style>